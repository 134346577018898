<template>
    <div>
        <div class="page-top-box">
            <div class="page-title-box">
                <div class="go-back">
                    <el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>|
                </div>
                {{ $route.meta.title }}
            </div>
        </div>
        <step-status :active="2"></step-status>
        <div class="page-content-box">
            <div class="icon"><img src="@/assets/img/common/success.png"/></div>
            <div class="msg">距离演出发布仅剩一步，请确认提交审核</div>
            <div class="tips">提交审核后，可在【演出列表】看到当前演出，然后请耐心等待秀动官方进行审核</div>
            <div class="btn">
                <el-button @click="goBack">上一步</el-button>
                <el-button type="primary" @click="publishConfirm" :loading="btnLoading">提交审核</el-button>
            </div>
            <div class="color-danger ML20 MT20"><i class="el-icon-warning"></i> 对于购票用户提交的退票申请，请及时在秀动APP专业版处理，以免影响后续结算。</div>
            <el-row class="more-info" :gutter="100">
                <el-col :sm="8">
                    <span class="iconfont">&#xe633;</span>
                    <div class="title">演出预览</div>
                    <div class="desc">
                        您可以使用当前账号登录WAP端预览页面
                        <el-button type="text" size="medium" @click="$refs.WapLink.show(id)">点击这里预览</el-button>
                        <!-- <el-link :href="`${ssweb}/event/${id}`" target="_blank">点击这里预览</el-link> -->
                    </div>
                </el-col>
                <el-col :sm="8">
                    <span class="iconfont">&#xe632;</span>
                    <div class="title">暂存草稿</div>
                    <div class="desc">
                        您可以暂时不提交审核，没有提交审核的演出，可以在
                        <el-link href="/manage/activity/draft" target="_blank">【演出草稿】</el-link>
                        页面找到
                    </div>
                </el-col>
                <el-col :sm="8">
                    <span class="iconfont">&#xe631;</span>
                    <div class="title">创建巡演</div>
                    <div class="desc">
                        提交审核后，可在
                        <el-link href="/manage/activity/list" target="_blank">【演出列表】</el-link>
                        基于当前演出，进行快速复制以形成巡演
                    </div>
                </el-col>
            </el-row>
        </div>
        <wap-link ref="WapLink"></wap-link>
    </div>
</template>

<script>
import StepStatus from "./components/step-status";
import WapLink from "./components/wap-link.vue";
export default {
    props: {
        id: {
            type: [Number, String],
            default: null,
        },
    },
    components:{StepStatus,WapLink},
    data(){
        return{
            redirect: this.$route.query.redirect || `/manage/activity/edit/step2/${this.id}`,
            // ssweb:`${process.env.VUE_APP_SSWEB_URL}`,
            btnLoading:false
        }
    },
    methods:{
        //返回
        goBack() {
            this.$router.push({
                path: this.redirect,
            });
        },
        //提交审核
        publishConfirm(){
            this.$confirm('确定提交审核?', '提示', {
                type: 'warning',
                center: true,
                closeOnClickModal: false
            }).then(() => {
                this.btnLoading = true;
                this.$request({
                    url: '/activity/publishConfirm',
                    params: {activityId: parseInt(this.id)},
                    method: "post",
                })
                    .then((res) => {
                        const { state, msg } = res.data;
                        if (state == 1) {
                            this.$router.push({
                                path: `/manage/activity/edit/finish/${this.id}`,
                                query: {
                                    redirect: this.$router.currentRoute.path,
                                },
                            });
                        } else {
                            this.$message.error(msg || "操作失败,请重试");
                        }
                    })
                    .catch(() => {
                        this.$message.error("操作失败,请重试");
                    })
                    .finally(() => {
                        this.btnLoading = false;
                    });
            }).catch(() => {})
            
        }
    }
}
</script>
<style lang="scss" scoped>
.page-content-box{
    text-align: center;
    padding: 70px 20px 110px 20px;
    .icon{
        img{
            width: 88px;
        }
    }
    .msg{
        margin-top: 40px;
        font-size: 18px;
        font-weight: 500;
    }
    .tips{
        color: $--color-text-regular;
        margin-top: 12px;
    }
    .btn{
        margin-top: 24px;
    }
    .more-info{
        display: inline-block;
        max-width: 900px;
        margin-top: 80px;
        .iconfont{
            font-size: 32px;
        }
        .title{
            font-weight: 500;
            margin: 8px 0 12px 0;
            font-size: 14px;
        }
        .desc{
            color: $--color-text-regular;
            line-height: 18px;
            a{
                color: $--color-theme;
                font-size: 13px;
            }
        }
    }
}
</style>